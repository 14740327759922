<template>
  <div>
    <loading :active.sync="loading"></loading>
    <v-row align="center" justify="center">
      <!-- {{ form.type }} -->
      <v-col cols="12" class="pa-0 ma-0">
        <!-- autoplay -->
        <video
          v-if="form.type === 'video'"
          style="width: 100%; height: auto"
          max-width="100vw"
          id="myVideo"
          @ended="getPoint()"
        >
          <source :src="form.knowledge_video_url" />
        </video>
        <v-col cols="12"   v-if="form.type === 'video'">
          <v-btn
            v-if="!playing"
            @click="play()"
            block
            large
            outlined
            x-large
            class="rounded-lg"
            text
            
            ><v-icon @click="play()">mdi-play</v-icon>play</v-btn
          >
          <v-btn
            v-else
            @click="play()"
            block
            large
            outlined
            x-large
            class="rounded-lg"
            text
            
            ><v-icon @click="play()">mdi-pause</v-icon>pause</v-btn
          >
          
        </v-col>
        <v-img
          v-if="form.type === 'image'"
          :src="
            form.knowledge_file_url
              ? form.knowledge_file_url
              : '@/assets/elearning.png'
          "
          style="
            display: block;
            margin-left: auto;
            margin-right: auto;
            object-fit: cover;
          "
        />
        <!-- width="414px"
          height="231px" -->

        <v-img
          v-if="form.type == 'pdf'"
          :src="
            form.knowledgeImg ? form.knowledgeImg : '@/assets/elearning.png'
          "
          style="
            display: block;
            margin-left: auto;
            margin-right: auto;
            object-fit: cover;
          "
        />
        <!-- <a @click="gotopdf(form.knowledge_file_url)">click link gotopdf</a> -->
        <!-- <pdf :src="form.knowledge_file_url"></pdf> -->
        <pdf
          v-for="(page, index) in numberOfPages"
          :key="`pdf_${index}`"
          :src="src"
          :page="page"
        />
        <span
          class="fn12-weight7 align-center"
          style="color: #1a347f"
          v-if="form.type == 'pdf'"
        >
          <a target="_blank" :href="form.knowledge_file_url"
            >Click to download PDF</a
          >
        </span>
      </v-col>

      <v-col cols="8">
        <span class="fn12 align-center" style="color: #06204b">
          {{
            form.type === "video"
              ? "Let’s start this video and you will earn"
              : ""
          }}
        </span>
      </v-col>
      <v-col cols="1" class="px-0">
        <img
          src="@/assets/BI.png"
          align="right"
          justify="right"
          style="width: 24px; height: 24px"
        />
      </v-col>
      <v-col cols="3">
        <span class="fn12-weight7 align-center" style="color: #1a347f"
          >{{ form.point }} Points</span
        >
      </v-col>
    </v-row>
    <v-divider class="my-2"></v-divider>
    <div style="color: #06204b" class="fn20-weight7">
      {{ form.knowledge_name }}
    </div>
    <span style="word-break: break-word; font-weight: 700" class="fn12">
      {{
        form.description
          ? form.description
          : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dui non mauris lobortis congue. Ut eu dui vitae praesent dolor nulla id. Cursus placerat amet tortor tincidunt mauris quis. Malesuada enim in aliquet ornare tristique. Id enim hac facilisi vitae at augue venenatis, eleifend. Sagittis risus nunc odio diam facilisis amet. Sed tincidunt fusce nullam volutpat sapien. Morbi quam nec bibendum pharetra elit interdum scelerisque. Sem aliquam, sapien, accumsan turpis libero aliquam risus. Posuere praesent sit metus nibh eget posuere sit."
      }}
    </span>
    <p
      class="mt-4 text-decoration-underline fn14-weight7"
      style="color: #06204b"
    >
      Terms & conditions
    </p>
    <v-btn
      block
      color="#1A347F"
      elevation="2"
      large
      outlined
      x-large
      class="mt-8 rounded-lg"
      style="color: #1a347f"
      @click="cancel()"
    >
      <span class="font">Back</span>
    </v-btn>
    <v-dialog v-model="dialogSuccess">
      <v-card class="rounded-lg">
        <v-container>
          <v-row align="center" justify="center" class="ma-4 text-center dense">
            <v-col cols="12" class="my-4">
              <span style="font-weight: 900; font-size: 24px; color: #1a347f">
                Thank You For
              </span>
              <br />
              <span
                style="font-weight: 900; font-size: 24px; color: #1a347f"
                v-if="form.type === 'video'"
              >
                Waching Video!
              </span>
              <span
                style="font-weight: 900; font-size: 24px; color: #1a347f"
                v-else
              >
                Reading !
              </span>
            </v-col>
            <v-col cols="12">
              <v-row justify="center" align="center" dense>
                <v-col cols="12" class="px-0">
                  <img
                    src="@/assets/BI.png"
                    justify="right"
                    style="width: 50px; height: 50px"
                  />

                  <span
                    class="align-center"
                    style="font-weight: 800; font-size: 20px; color: #1a347f"
                  >
                    You Earn {{ form.point }} Points</span
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row justify="center" align="center" dense>
            <v-col cols="12">
              <v-btn
                block
                large
                outlined
                x-large
                class="mt-4 rounded-lg buttoncolor"
                @click="cancel()"
              >
                <span class="font">Back to homepage</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import pdf from "vue-pdf";
import { Decode } from "@/services";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  components: {
    pdf,
    Loading,
  },

  data() {
    return {
      categoryName: "",
      categoryDescription: "",
      activeFlag: true,
      pic1: "",
      picture_1: "",
      showImage: "",
      imageName1: "",
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
        pic: [(v) => !!v || "กรุณาอัพโหลดรูป"],
      },
      checkValidate: true,
      lazy: false,
      user: [],

      frontId: "",
      form: {},
      dialogSuccess: false,
      src: null,
      numberOfPages: 0,
      loading: false,
      userData: [],
      playing: false,
    };
  },
  // mounted() {
  //   this.src.promise.then((pdf) => {
  //     this.numberOfPages = pdf.numPages;
  //   });
  // },

  async created() {
    // this.loading = true;
    // this.getPoint();
    const urlParams = this.$route.query;
    this.frontId = urlParams.reward;
    await this.getProfile();
    // await this.getUser();
    await this.getOneFront();
    // if (this.form.knowledge_video_url) {
    //   this.loading = false;
    // }
    // await setTimeout(() => {
    // }, 2000);
  },
  methods: {
    // donutautoplay() {
    //   setTimeout(() => {
    //     console.log("เข้า");
    //     document.getElementById("myVideo").play();
    //   }, 2000);
    //   // document.getElementById("myVideo").click();

    //   // document.getElementById("myVideo").play();
    // },
    play() {
      this.playing = !this.playing;
      console.log(this.playing);
      if (this.playing) {
        myVideo.play();
      } else {
        myVideo.pause();
      }
    },
    gotopdf(val) {
      window.open(val);
    },
    countdown() {
      console.log("aaaaaaaaa");
      setTimeout(() => {
        this.getPoint();
      }, 5000);
    },
    async getProfile() {
      let _this = this;
      this.$liff.init({ liffId: "1656786311-AEGkzBkO" }).then(() => {
        // alert('in line')
        if (!this.$liff.isLoggedIn()) {
          this.$liff.login();
          console.log("ยังไม่ได้ล้อคอิน");
        } else {
          let that = _this;
          this.$liff.getProfile().then(async function (profile) {
            console.log("ล้อคอินละ", profile);
            that.lineId = profile.userId;
            await that.getUser();
          });
        }
      });
      // let that = this;
      // this.$liff.getProfile().then(async function (profile) {
      //   console.log("ล้อคอินละ", profile);
      //   that.lineId = profile.userId;
      //   await that.getUser();
      // });
    },
    async getUser() {
      console.log("lineIdlineIdlineIdlineId", this.lineId);
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/users/findUserByLineId/${this.lineId}`
        // `${process.env.VUE_APP_API}/users/findUserByLineId/U0354ee79b4cd7f2973027952ad0f6fc5`
      );
      console.log("user Data", response.data.data);
      this.userData = response.data.data;
    },
    async getPoint(e) {
      // console.log("getPoint");

      // this.user = JSON.parse(Decode.decode(localStorage.getItem("userBiData")));
      const data = {
        addPoint: this.form.point,
        knowledgeCategory3Id: this.form.knowledgeCategory3Id,
      };
      const response = await this.axios.put(
        `${process.env.VUE_APP_API}/users/${this.userData.id}`,
        data
      );
      console.log("response", response.data);
      // message: "User was updated successfully"
      // response_status: "SUCCESS"
      if (response.data.response_status == "SUCCESS") {
        this.dialogSuccess = true;
      }
    },
    async getOneFront() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/knowledge/${this.frontId}
          `
      );
      console.log("getOneKnowledge", response.data);
      this.form = response.data.data;
      console.log("aaaaaa", this.form.type);
      if (this.form.type == "image") {
        this.countdown();
      }
      if (this.form.type == "pdf") {
        this.src = pdf.createLoadingTask(this.form.knowledge_file_url);
        this.src.promise.then((pdf) => {
          this.numberOfPages = pdf.numPages;
        });
        this.countdown();
      }
    },
    showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.picture_1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          // console.log(this.pic1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    cancel() {
      this.$router.go(-1);
    },
    async submit() {
      if (this.$refs.createForm.validate(true)) {
        this.user = JSON.parse(
          Decode.decode(localStorage.getItem("userBiData"))
        );
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        const data = {
          category: this.categoryName,
          description: this.categoryDescription,
          activeFlag: this.activeFlag,
          categoryImg: this.pic1,
        };
        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/categories`,
          data,
          auth
        );
        console.log("createBrand", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `สร้างหมวดหมู่สินค้าสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$router.push("ManageCategory");
        } else {
          this.$swal.fire({
            icon: "error",
            text: response.data.response_description,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
  },
};
</script>

<style scoped>
.font {
  font-family: "OpenSansBold" !important;
}
.buttoncolor {
  color: white;
  background: linear-gradient(268.1deg, #07204b 7.54%, #1a347f 100.72%);
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;

  height: 57px;
}
</style>
